import React from "react";

const Cards=(props)=>{
    return(
        <div className="display-cards">
            <img src={props.imgUrl} alt="" className="images" />
                <a href="#shop-now" className="gallery-button">View</a>
        </div>
    );
}

export default Cards;