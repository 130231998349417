import React from "react";
import './footer.css'

const tiktok="http://www.tiktok.com/@liveup_essentials";
const instagram="https://www.instagram.com/liveup_essentials";

const Footer=()=>{
    return(
        <section className="footer" id="footer">
            <div className="footer-container">
                <div className="footer-company">
                    <p>Liveup</p>
                </div>
                <div className="services">
                    
                </div>
                <div className="social-media">
                    <a href={instagram} className="instagram" target="noopener" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                    <a href={tiktok} className="tiktok" target="noopener" rel="noreferrer"><i className="fa-brands fa-tiktok"></i></a>
                </div>
            </div>
        </section>
    );
}
export default Footer;