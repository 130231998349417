import React, { useState } from "react";
import "./header.css"
const Header= () =>{
  const[Toggle,showMenu]=useState(false);

    return(
      <header className="header">
        <nav className="nav">
          <a href="index.html" className="nav-logo">Liveup</a>
          <div className={Toggle ? "nav-menu show-menu" : "nav-menu"}>
            <ul className="nav-list">
              <li className="nav-item">
                <a href="#home" className='nav-link active-link'>
                <i className='fa-solid fa-house nav-icon'></i>Home
                </a>
              </li>
              
              <li className="nav-item">
                <a href="#about" className='nav-link active-link'>
                <i className='fa-solid fa-address-card nav-icon'></i>About
                </a>
              </li>

              <li className="nav-item">
                <a href="#contact" className='nav-link'>
                <i className="fa-solid fa-envelope nav-icon"></i>Contact
                </a>
              </li>
            </ul>
            <i className="fa-solid fa-square-xmark nav-close" onClick={() =>showMenu(!Toggle) }></i>
          </div>
          <div className="nav-toggle" onClick={() =>showMenu(!Toggle) }>

          <i className="fa-solid fa-table-cells-large"></i>
          </div>
        </nav>
      </header>
    )
}
export default Header;