import './App.css';
import Header from './components/Header/header.jsx'
import Home from './components/Home/home.jsx';
import Gallery from './components/Gallery/gallery.jsx'
import Shop from './components/shop/shop.jsx'
import Footer from './components/Footer/footer.jsx';

function App() {
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Gallery/>
      <Shop/>
      <Footer/>
    </div>
  );
}

export default App;
