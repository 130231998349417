import React from "react";
import Card from './cards'
import './galley.css'
import One from '../../assets/one.jpg'
import Hydrate from '../../assets/three.jpg'
import Two from '../../assets/two.jpg'

const Gallery=()=>{
    return(
        <section className="gallery" id="gallery">
                <div className="cards">
                    <Card imgUrl={Hydrate}/>
                    <Card imgUrl={One}/>
                    <Card imgUrl={Two}/>
                </div>
        </section>
    );
}

export default Gallery;