import React from "react";
import "./home.css"


const Home=()=>{
    return(
        <section className="section home" id="#home">
            <div className="home-container">
                <div className="home-layout"> 
                </div>
            </div>

        </section>
    );
}
export default Home;