import React from "react";
import Black from '../../assets/black.jpeg'
import Blue from '../../assets/blue.jpg'
import Mix from '../../assets/mix.jpg'
import './shop.css'

const bottleurl="https://www.amazon.com/Insulated-Water-Bottle-Stainless-Sports/dp/B0CMDKXXJH/ref=sr_1_3?crid=2JEN9M0A6M1V&dib=eyJ2IjoiMSJ9.NB8cGuiw4LpMxVfuoD_GvI1KWUv3rmMP45caRyNBPjzvslKEnTy0TtHJAjNCGhRaUwIKG8RoVueuut79giJWRQ.6_6Qw82Ls0N8kw-VOutSABj1KWpWcCs4Hfz4g4xx1P0&dib_tag=se&keywords=liveup&qid=1710349021&sprefix=liveup%2Caps%2C339&sr=8-3";

const Shop=()=>{
    return(
        <section className="shop" id="shop-now">
            
            <div className="shop-container">
                <div className="shop-title-container">
                    <p className="shop-title">Best Sellers</p>
                    
                </div>
                <div className="shop-bottle">
                    <div className="bottle-color">
                        <img src={Black} alt="" />
                        <a href={bottleurl} target="noopener" rel="noreferrer" className="shop-all-liveup">Shop Now</a> 

                    </div>
                    <div className="bottle-color">
                        <img src={Blue} alt="" />
                        <a href={bottleurl} target="_blank" rel="noreferrer" className="shop-all-liveup">Shop Now</a> 

                    </div>
                    <div className="bottle-color">
                        <img src={Mix} alt="" />
                        <a href={bottleurl} target="_blank" rel="noreferrer" className="shop-all-liveup">Shop Now</a> 
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Shop;
